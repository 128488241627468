import { Avatar, Box, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSettingData } from '../../../../../action/settingData'
import logo from "../../../../../assets/img/nepal-government.png"
import { AppMisc } from "../../../../../misc/appMisc"
import { AppUtils } from "../../../../../utils/appUtils"
import { CARD_VALIDATION_URL, GENDER_OPTIONS, USER_INFO } from "../../../../../utils/constants"
import { PALIKA } from "../../../../../utils/constants/forms"
import { SessionStorage } from "../../../../../utils/storage/sessionStorage"
import QrCode from "../../../staff-details/helpers/QrCode"
import styles from "../style"

export default function CardFront({ staffInfo, ...props }) {
  const userInfo = SessionStorage.getItem(USER_INFO) || {};
  const [addressInfo, setAddressInfo] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({});
  const [appointedServiceInfo, setAppointedServiceInfo] = useState({});
  const officeName = userInfo.officeType === PALIKA ? AppMisc.getMunicipalityName(userInfo.officeName) : userInfo.officeName;
  const dispatch = useDispatch();
  const defaultValues = useSelector(state => state.settingData);

  useEffect(() => {
    setAddressInfo(staffInfo.addressDetail);
    setPersonalInfo(staffInfo.personalInfo || {});
    setAppointedServiceInfo(staffInfo.appointedServiceInfo || {});
  }, [staffInfo])

  useEffect(() => {
    !defaultValues && dispatch(getSettingData());
  }, [defaultValues])

  function getAddressLabel(address) {
    if (address) {
      return `${AppMisc.getMunicipalityName(address.permanentAddressPalikaName)}-${address?.permanentAddressWardNumber && AppUtils.replaceWithNepaliDigit(address?.permanentAddressWardNumber)}, ${address?.permanentAddressGaunOrTole}`
    }
    return "-";
  }

  const classes = styles();
  return (
    <Box className={classes.cardWrapper}>
      <Box className={classes.card}>
        <Box className={classes.cardHead}>
          <Box className={classes.logo}>
            <img className={classes.officeLogo} src={logo} alt="government logo" />
          </Box>
          <Box className={classes.officeName}>
            {userInfo.officeName &&
              <>
                <Typography variant="body1">{officeName}</Typography>
                {
                  userInfo.officeType === PALIKA &&
                  <Typography variant="body1">{officeName.split(" ").slice(-1) === "गाउँपालिका" ? "गाउँ कार्यपालिकाको कार्यालय" : "नगर कार्यपालिकाको कार्यालय"}</Typography>
                }
                <Typography>{AppMisc.getProvinceName(userInfo.province)}, {AppMisc.getDistrictName(userInfo.district)}, नेपाल</Typography>
              </>
            }
          </Box>
          <Box className={classes.logo}>
            {defaultValues?.logoUrl &&
              <img className={classes.officeLogo} src={defaultValues?.logoUrl.absolutePath} alt="logo" />
            }
          </Box>
        </Box>
        <Box className={classes.cardBody}>
          <Typography variant="caption">कर्मचारी संकेत: {staffInfo.personalInfo?.staffCodeNumber && AppUtils.replaceWithNepaliDigit(staffInfo.personalInfo.staffCodeNumber)}</Typography>
          <Box className={classes.cardContent}>
            <Box className={classes.cardImage}>
              <Box>
                <Avatar variant="rounded" alt="Staff Photo" src={personalInfo?.photo?.absolutePath} className={classes.image} />
              </Box>
              {defaultValues?.signatureUrl &&
                <img className={classes.signature} src={defaultValues?.signatureUrl.absolutePath} alt="signature" />
              }
              {defaultValues?.stampUrl &&
                <img className={classes.stamp} src={defaultValues?.stampUrl.absolutePath} alt="signature" />
              }
            </Box>
            <Box className={classes.cardDetails}>
              <Typography>नाम: {personalInfo.fullNameInNepali}</Typography>
              <Typography>लिङ्ग: {AppMisc.getLabelFromValue(personalInfo.gender, GENDER_OPTIONS)}</Typography>
              <Typography>दर्जा: {appointedServiceInfo?.categoryAndLabel}</Typography>
              <Typography>पद: {AppMisc.getLabelFromValue(staffInfo.currentServiceInfo?.post, props.postOptions)}</Typography>
              <Typography>रक्त समूह: {staffInfo.othersInfo?.bloodGroup || "-"}</Typography>
              <Typography>फोन नम्बर: {staffInfo.othersInfo?.mobileNumber ? AppUtils.replaceWithNepaliDigit(staffInfo.othersInfo?.mobileNumber) : "-"}</Typography>
              <Typography>जन्म मिति: {personalInfo.dateOfBirthInBS && AppUtils.replaceWithNepaliDigit(personalInfo.dateOfBirthInBS)}</Typography>
              <Typography>नागरिकता नं.: {personalInfo.citizenshipNumber && AppUtils.replaceWithNepaliDigit(personalInfo.citizenshipNumber)}</Typography>
              <Typography>ठेगाना: {getAddressLabel(addressInfo)}</Typography>
            </Box>
            <Box className={classes.cardQr}>
              <Box className={classes.qr}>
                <QrCode
                  value={`${CARD_VALIDATION_URL}?id=${staffInfo.id}`}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}